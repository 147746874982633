const url = 'assets/images/homens_a-volta-dos-que-nao-foram.pdf';
const pdfContainer = document.querySelector('.spec__pdf');
const specDownload = document.querySelector('.spec__download');
const specText = document.querySelector('.spec__text');

function renderPDF() {
    pdfjsLib.getDocument(url).promise.then(pdf => {
        let firstPageHeight = 0;

        function renderPage(pageNum) {
            pdf.getPage(pageNum).then(page => {
                const canvas = document.createElement('canvas');
                canvas.className = 'spec__page';
                pdfContainer.appendChild(canvas);

                const viewport = page.getViewport({
                    scale: 1
                });
                const scale = Math.min(pdfContainer.clientWidth / viewport.width);
                const scaledViewport = page.getViewport({
                    scale: scale
                });

                canvas.width = scaledViewport.width;
                canvas.height = scaledViewport.height;

                const ctx = canvas.getContext('2d');
                const renderContext = {
                    canvasContext: ctx,
                    viewport: scaledViewport
                };

                page.render(renderContext).promise.then(() => {
                    if (pageNum === 1) {
                        firstPageHeight = canvas.offsetHeight;
                    }

                    if (pageNum === pdf.numPages) {
                        pdfContainer.scrollTop = firstPageHeight / 2;
                    }
                });
            });
        }

        for (let pageNum = 1; pageNum <= pdf.numPages; pageNum++) {
            renderPage(pageNum);
        }
    });
}

const handleScroll = function () {
    const scrollTop = window.scrollY;
    const docHeight = document.body.offsetHeight;
    const windowHeight = window.innerHeight;
    const scrollPercent = (scrollTop / (docHeight - windowHeight)) * 100;
    const overlay = document.querySelector('.spec__overlay');
    const paragraphs = document.querySelectorAll('.spec__text p');
    const fadeOutScrollPercentOverlay = 5;
    const fadeOutScrollPercentParagraphs = 1;

    if (scrollPercent >= fadeOutScrollPercentOverlay) {
        overlay.style.opacity = 0;
    } else {
        overlay.style.opacity = 1 - (scrollPercent / fadeOutScrollPercentOverlay);
    }

    paragraphs.forEach(p => {
        if (scrollPercent >= fadeOutScrollPercentParagraphs) {
            p.style.opacity = 0;
        } else {
            p.style.opacity = 1 - (scrollPercent / fadeOutScrollPercentParagraphs);
        }
    });

    if (scrollPercent >= fadeOutScrollPercentParagraphs) {
        specDownload.classList.add('spec__download--visible');
    } else {
        specDownload.classList.remove('spec__download--visible');
    }
};

const manageFeatures = function () {
    if (window.innerWidth > 767 && window.innerHeight > 599) {
        if (!pdfContainer.hasChildNodes()) {
            renderPDF();
        }
        window.addEventListener('scroll', handleScroll);
        if (specText.contains(specDownload)) {
            specText.removeChild(specDownload);
            document.body.appendChild(specDownload);
        }
    } else if (window.innerWidth > 767) {
        if (!specText.contains(specDownload)) {
            specText.appendChild(specDownload);
        }
    } else {
        window.removeEventListener('scroll', handleScroll);
        pdfContainer.innerHTML = '';

        if (!specText.contains(specDownload)) {
            specText.appendChild(specDownload);
        }
    }
};

manageFeatures();
window.addEventListener('resize', manageFeatures);