document.addEventListener('DOMContentLoaded', () => {
	const contactBtn = document.querySelector('.menu__btn');
	const contact = document.querySelector('.contact');
	const contactClose = document.querySelector('.contact__close');
	const windowWidth = window.innerWidth;

	if (contactBtn && contact) {
		contactBtn.addEventListener('click', () => {
			contactBtn.classList.toggle('menu__btn--active');
			contact.classList.toggle('contact--show');

			if (windowWidth < 1200) {
				if (menu && btnMenu) {
					menu.classList.remove('open');
					btnMenu.classList.remove('open');
				}
			}
		});
	}

	if (contactClose) {
		contactClose.addEventListener('click', () => {
			if (contactBtn && contact) {
				contactBtn.classList.remove('menu__btn--active');
				contact.classList.remove('contact--show');
			}
		});
	}

	document.addEventListener('keydown', (e) => {
		if (e.key === 'Escape') {
			if (contactBtn && contact) {
				contactBtn.classList.remove('menu__btn--active');
				contact.classList.remove('contact--show');
			}
		}
	});
});