let i = 0;
const txt = 'Erik Botelho';
const speed = 100;

const typeWriter = () => {
    if (i < txt.length) {
        document.getElementById("erik").innerHTML += txt.charAt(i);
        i++;
        setTimeout(typeWriter, speed);
    }
};

document.addEventListener('DOMContentLoaded', () => {
    if (document.querySelector('main').classList.contains('home')) {
        setTimeout(() => {
            typeWriter();
        }, 2000);
    }
});