const menuBtn = document.getElementById('menuBtn');
const menu = document.querySelector('.menu');
const header = document.querySelector('.header');

document.addEventListener('DOMContentLoaded', () => {
	if (menuBtn && menu) {
		const handleMenuToggle = () => {
			if (window.innerWidth < 1200) {
				menu.classList.toggle('menu--open');
				menuBtn.classList.toggle('open');
			}
		};

		menuBtn.addEventListener('click', handleMenuToggle);

		window.addEventListener('resize', () => {
			if (window.innerWidth >= 1200) {
				menu.classList.remove('menu--open');
				menuBtn.classList.remove('open');
			}
		});
	}
});

window.addEventListener('scroll', () => {
	const windowScroll = window.scrollY;

	if (windowScroll > 30) {
		header.classList.add('header--sticky');
	} else {
		header.classList.remove('header--sticky');
	}
});